import { ODWebUtils } from '@odc/od-react-belt'
import { ToastOptions } from 'react-toastify'

export type WebAppOptions = {
  WS_SERVER_ADDRESS: string
  SERVER_ADDRESS: string
  SHOW_BUILD_INFO: boolean
  localServerAddress: string
  wsLocalServerAddress?: string
  enableDevOptions: boolean
  FILL_MOCK_DATA: boolean
  SIMULATE_DELAY: number
  LOCAL_STORAGE_KEY_TOKEN: string
  SERVER_DEFINED_ERROR_PREFIX: string
  defaultErrorToastOptions: Partial<ToastOptions>
  defaultInfoToastOptions: Partial<ToastOptions>
  defaultSuccessToastOptions: Partial<ToastOptions>
  siteAddress: string
  useTestData: boolean
  connectLocalAgent: boolean
}

const ProdWebAppOptions: WebAppOptions = {
  WS_SERVER_ADDRESS: '',
  // SERVER_ADDRESS: 'http://prod-cils-main-server.ap-northeast-2.elasticbeanstalk.com',
  // SERVER_ADDRESS: 'http://13.209.18.233',
  SERVER_ADDRESS: 'https://prod-api.cils.io',
  SHOW_BUILD_INFO: true,
  localServerAddress: 'http://localhost:4000',
  wsLocalServerAddress: 'ws://localhost:4000',
  enableDevOptions: false,
  FILL_MOCK_DATA: false,
  SIMULATE_DELAY: 0,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'CE',
  defaultErrorToastOptions: { autoClose: 5000 },
  defaultInfoToastOptions: { autoClose: 5000 },
  defaultSuccessToastOptions: { autoClose: 5000 },
  // siteAddress: 'http://prod-cils-web.s3-website.ap-northeast-2.amazonaws.com',
  siteAddress: 'http://tomocube.cils.io',
  useTestData: false,
  connectLocalAgent: true,
}

// Old QA 배포
const QAWebAppOptions: WebAppOptions = {
  WS_SERVER_ADDRESS: '',
  SERVER_ADDRESS: 'http://3.34.138.177',
  SHOW_BUILD_INFO: true,
  localServerAddress: 'http://localhost:4000',
  wsLocalServerAddress: 'ws://localhost:4000',
  enableDevOptions: true,
  FILL_MOCK_DATA: false,
  SIMULATE_DELAY: 0,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'CE',
  defaultErrorToastOptions: { autoClose: 5000 },
  defaultInfoToastOptions: { autoClose: 5000 },
  defaultSuccessToastOptions: { autoClose: 5000 },
  siteAddress: 'http://qa-cils-web.s3-website.ap-northeast-2.amazonaws.com',
  useTestData: false,
  connectLocalAgent: true,
}

// 20250121 배포 테스트용
const QA2WebAppOptions: WebAppOptions = {
  WS_SERVER_ADDRESS: '',
  SERVER_ADDRESS: 'https://api-qa2.cils.io',
  SHOW_BUILD_INFO: true,
  localServerAddress: 'http://localhost:4000',
  wsLocalServerAddress: 'ws://localhost:4000',
  enableDevOptions: true,
  FILL_MOCK_DATA: false,
  SIMULATE_DELAY: 0,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'CE',
  defaultErrorToastOptions: { autoClose: 5000 },
  defaultInfoToastOptions: { autoClose: 5000 },
  defaultSuccessToastOptions: { autoClose: 5000 },
  siteAddress: 'https://qa2.cils.io',
  useTestData: false,
  connectLocalAgent: true,
}

const DevOptions: WebAppOptions = {
  WS_SERVER_ADDRESS: '',
  SERVER_ADDRESS: 'http://192.168.0.100:8081',
  SHOW_BUILD_INFO: true,
  localServerAddress: 'http://localhost:4000',
  wsLocalServerAddress: 'ws://127.0.0.1:4000',
  enableDevOptions: true,
  FILL_MOCK_DATA: true,
  SIMULATE_DELAY: 0,
  LOCAL_STORAGE_KEY_TOKEN: 'token',
  SERVER_DEFINED_ERROR_PREFIX: 'CE',
  defaultErrorToastOptions: { autoClose: 5000 },
  defaultInfoToastOptions: { autoClose: 5000 },
  defaultSuccessToastOptions: { autoClose: 5000 },
  siteAddress: 'http://192.168.0.100:3031',
  useTestData: true,
  connectLocalAgent: true,
}

export const AppOptions =
  process.env.REACT_APP_ENV === 'prod'
    ? ProdWebAppOptions
    : process.env.REACT_APP_ENV === 'qa'
    ? // ? QAWebAppOptions
      QA2WebAppOptions
    : DevOptions

ODWebUtils.setAppOptions(AppOptions)
